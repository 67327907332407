<template>
  <div class="result">
    <Header :header_data="header_data" :noClicking="true" />
    <div class="main">
        <img src="../../../assets/student/testcomplete.png" alt="">
        <div class="text">{{ fs + '分' }}</div>
        <div class="back" @click="back">返回</div>
    </div>
  </div>
</template>
<script>
import Header from "../components/zjfheader.vue";
import { onMounted, reactive, toRefs } from "vue-demi";
import { getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import { getEnglishText, saveLearnedTextScore, saveMyBreakpoint, getMyBreakpoint } from '@/api/student'
import { ElMessage } from "element-plus"
export default {
  components: {
    Header,
  },
  setup() {
    const store = useStore()
    const { appContext } = getCurrentInstance()
    const that = appContext.config.globalProperties
    const data = reactive({
        header_data: [],
        fs: '',
        notes: {}
    })
    onMounted(() => {
      let params = {
        ddzt: 1,
        dyid: that.$route.query.dyid,
        xsid: store.state.userInfo.yhid,
        zid: that.$route.query.zid,
        zsdid: sessionStorage.getItem('zsdid')
      }
      getMyBreakpoint(params).then(ele => {
        if (ele.success) {
          let notes = ele.result.ddxx
          data.notes = JSON.parse(notes)
          data.fs = data.notes.cscj
          delete data.notes.step
          delete data.notes.details
          saveLearnedTextScore(data.notes).then(res => {
            console.log('保存成绩成功')
          }).catch(()=>{
            ElMessage({
                message: '保存成绩单失败',
                type: 'warning'
            })
          })
        } else {
          ElMessage({
            message: ele.resultDesc,
            type: 'warning'
          })
        }
      })
        // 获取课文
        getEnglishText({ kwid: that.$route.query.kwid }).then(res => {
          if(res.success) {
            data.header_data = [
              { title: "图谱英语 — ", path: "newEnglish" },
              { title: that.$route.query.zmc + " — ", path: `/units?zid=${that.$route.query.zid}&zmc=${that.$route.query.zmc}` },
              { title: that.$route.query.dymc + " — ", path: `/chapter?zid=${that.$route.query.zid}&zmc=${that.$route.query.zmc}&dyid=${that.$route.query.dyid}&dymc=${that.$route.query.dymc}` },
              { title: res.result.kwmc, path: "" },
            ]
          } else {
            ElMessage({
              message: res.resultDesc,
              type: 'warning'
            })
          }
        })
    })
    const back = () => {
      that.$router.replace({ name: 'chapter', query: { dyid: that.$route.query.dyid, dymc: that.$route.query.dymc, zmc: that.$route.query.zmc, zid: that.$route.query.zid } })
    }
    return {
      ...toRefs(data),
      back
    };
  },
};
</script>
<style lang="scss" scoped>
.result {
  width: 100%;
  height: 100%;
  background: url("../../../assets/student/zjfstudyBanner.png") no-repeat;
  background-size: 100% 100%;
  .main{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      img{
         width: 530px;
      }
      .text{
        position: absolute;
        color: #fff;
        font-size: 5.5vw;
        top: 61%;
        left: 45%;
        transform: translate(-50%, -50%);
        -webkit-text-stroke: 0.25vw #FF4545;
        text-shadow: 5px 5px 5px #333;
      }
      .back{
        width: 12.96vw;
        height: 4.11vw;
        background: url("../../../assets/student/wordysxbtn.png") no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
        text-align: center;
        line-height: 4.11vw;
        font-size: 1.4vw;
        color: #fff;
        margin: 20px auto 0px;
      }
  }
}
</style>